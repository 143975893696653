import classNames from "classnames";
import React from "react";

export const MessageBox = ({ text, red }: { text: string | string[]; red?: boolean }) => {
    return <div className={classNames("MessageBox", { "MessageBox--red": red })}>
        {Array.isArray(text)
            ? text.map((paragraph, index) => (
                <div
                    key={index}
                    dangerouslySetInnerHTML={{ __html: paragraph }}
                />
            ))
            : text && (
                <div
                    dangerouslySetInnerHTML={{ __html: text }}
                />
            )}
    </div>;
};
